/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
@import '~antd/dist/antd.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.min.css';

/* Roboto LIGHT */
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Light.woff2") format("woff2"),
    /* Super modern browsers */ url("./fonts/Roboto/Roboto-Light.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: 300;
  font-style: normal;
}

/* Roboto REGULAR */
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Regular.woff2") format("woff2"),
  url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Roboto MEDIUM */
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Medium.woff2") format("woff2"),
  url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Roboto BOLD */
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Bold.woff2") format("woff2"),
  url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  position: relative;
  color: #272424;
  height: 100vh;
  background-color: #ffffff;
  font-family: Roboto, Arial, Helvetica, Helvetica Neue, serif;
  font-size: 12px;
  line-height: 20px;
  /*overflow-y: hidden;*/
  overflow: auto;
}

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  color: #fff;
}

/*p {*/
/*  font-size: 24px;*/
/*}*/

li {
  list-style: none;
}

a {
  color: #40a9ff;
  opacity: 0.75;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}
